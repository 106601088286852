import { render, staticRenderFns } from "./WarehouseShelves.vue?vue&type=template&id=cde46d94&scoped=true&"
import script from "./WarehouseShelves.vue?vue&type=script&lang=js&"
export * from "./WarehouseShelves.vue?vue&type=script&lang=js&"
import style0 from "./WarehouseShelves.vue?vue&type=style&index=0&id=cde46d94&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cde46d94",
  null
  
)

export default component.exports